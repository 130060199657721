@import 'assets/styles/variable';
@import 'assets/styles/mixins';

.login {
  overflow: hidden;
  @include absolute-center();
  div {
    width: 552px;
    height: 253px;
    background: #fff;
    padding: 46px 103px 54px 104px;
    border-radius: 10px;
    border: 1px solid #878787;
    text-align: center;
  }
  input {
    background-color: #fff;
    border: 1px solid #000000;
    margin-bottom: 16px;
  }
  span {
    color: #1c344a;
  }
}
.searchbox {
  @include gap(12px);
  > form {
    // &:first-child {
    //   position: relative;
    //   &::before {
    //     @include pseudo(1px, 24px);
    //     left: 284px;
    //     position: absolute;
    //     background: $secondary;
    //   }
    // }
    @include flex-column-center;
    justify-content: space-between;
    width: calc(100% - 6px);
    height: 72px;
    padding: 0 24px;
    background: #fff;
    border: $secondary-border;
    border-radius: 10px;
    margin: 14px 0 8px 0;
    > em {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.date {
  padding-left: 12px;
  font-weight: 500;
}

.total {
  height: 44px;
  margin-top: 21px;
  margin-bottom: 15px;
  @include flex-column-center;
  justify-content: flex-end;

  h1 {
    font-size: 20px;
    margin-right: 33px;
  }
  > div {
    border-radius: 10px;
    background: #fff;
    border: 1px solid #dfe3eb;
    width: 865.25px;
    padding: 9px 0;
    @include flex-column-center;
  }
  button {
    border-radius: 6px;
  }
  span {
    width: 141px;
    text-align: left;
  }
  span:nth-child(1) {
    width: 130px;
    margin-right: 33px;
    text-align: center;
  }
}
.reset_btn {
  width: 32px;
  padding: 6px;
  border: 1px solid #386a98;
  border-radius: 4px;
  cursor: pointer;
}
