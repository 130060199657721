@import 'assets/styles/variable';
@import 'assets/styles/mixins';
.talk {
  span {
    color: #4d6aed;
    font-weight: 700;
  }
  section {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
  }
}

.talk {
  @include gap(24px);
  margin-top: 16px;
  width: 1224px;
  height: 680px;
}

.img_list {
  width: 184px;
  height: 680px;
  padding: 32px 0 0 24px;
  > div {
    width: 136px;
    height: 584px;
    margin-top: 24px;
    @include flex-column();
    align-items: center;
    gap: 8px;
  }
  &__view {
    height: 504px;
    @include flex-column();
    align-items: center;
    gap: 8px;
    .img_view {
      width: 120px;
      height: 120px;
    }
  }
  img {
    border-radius: 16px;
    width: auto;
    height: auto;

    max-width: 120px;
    max-height: 120px;
  }
}
.writing_menu {
  @include space-between;
  width: 1016px;
  height: 56px;
  margin-bottom: 16px;
  padding: 0 44px 0 32px;
  &__return {
    @include flex-center;
    height: 38px;
    width: 210px;
    font-size: 16px;
    font-weight: 600;
    gap: 15px;
    &::before {
      @include pseudo(10px, 12px);
      background: url('../../../assets/img/chevron_gray.svg') center/16px
        no-repeat;
      transform: rotate(90deg);
    }
  }
}
.contents {
  width: 1016px;
  padding: 32px 48px 32px 32px;
  > div {
    margin-top: 16px;
  }
  &__info {
    padding: 30px 16px 50px 16px;
  }
  &__box {
    padding: 32px 0 0 16px;
    dl:nth-child(2) {
      margin-bottom: 18px;
    }
    dl {
      display: flex;
      dt {
        font-weight: 600;
        color: #707070;
        width: 76px;
      }
      dt + dd {
        margin-left: 16px;
      }
    }
    div + dl {
      margin: 24px 0;
    }
    img {
      background: #a7a7a7;
      border-radius: 16px;
      width: auto;
      height: auto;
      width: 150px;
      height: 150px;
      max-width: 150px;
      max-height: 150px;
    }
    .img_view {
      width: 150px;
      height: 150px;
    }
  }
  &__sub {
    display: inline-block;
    margin: 0 24px 8px 0;
    span {
      font-weight: 500;
      &:nth-child(1) {
        color: #a7a7a7;
      }
      &:nth-child(2) {
        color: #707070;
      }
    }
    span + span {
      margin-left: 8px;
    }
  }
  &__question {
    width: 828px;
    height: 90px;
  }
}
.img_view {
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
  }
  &__dday {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 9px;
    padding: 2px 10px;
    font-size: 10px;
    color: #ffffff;
  }
}

.preview_img {
  @include flex-center;
  width: 100%;
  height: 100%;
  img {
    width: auto;
    height: auto;
    max-width: 800px;
    max-height: 650px;
  }
  &_del {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    img {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }
  }
}
