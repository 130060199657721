@import 'assets/styles/mixins';
@import 'assets/styles/variable';

.searchbox {
  @include gap(12px);
  margin: 15px 0 20px;
  > * {
    &:first-child {
      position: relative;
      &::before {
        @include pseudo(1px, 24px);
        left: 284px;
        position: absolute;
        background: $secondary;
      }
    }
    @include flex-column-center;
    justify-content: space-between;
    width: calc(100% - 6px);
    height: 72px;
    padding: 0 24px;
    background: #fff;
    border: $secondary-border;
    border-radius: 10px;
    > em {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.date {
  padding-left: 12px;
  font-weight: 500;
}

.total {
  background: #ebedf2;
  border-radius: 10px;
  height: 44px;
  margin-bottom: 15px;
  @include flex-center;
  > dl {
    > * {
      display: inline-block;
    }
    > dt {
      margin-right: 9px;
    }
    > dd {
      margin-right: 70px;
      font-weight: 500;
    }
  }
  button {
    border-radius: 6px;
  }
}
