/* margin-common */
.mt_16 {
  margin-top: -16px;
}
.mt_18 {
  margin-top: -18px;
}
.mt3 {
  margin-top: 3px;
}
.mt4 {
  margin-top: 4px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt14 {
  margin-top: 14px;
}
.mt16 {
  margin-top: 16px;
}
.mt18 {
  margin-top: 18px;
}
.mt20 {
  margin-top: 20px;
}
.mt23 {
  margin-top: 23px;
}
.mt24 {
  margin-top: 24px;
}
.mt30 {
  margin-top: 30px;
}
.mt35 {
  margin-top: 35px;
}
.mt40 {
  margin-top: 40px;
}
.mt48 {
  margin-top: 48px;
}
.mt50 {
  margin-top: 50px;
}
.mt60 {
  margin-top: 60px;
}
.mt66 {
  margin-top: 66px;
}
.mt70 {
  margin-top: 70px;
}
.mt80 {
  margin-top: 80px;
}
.mt90 {
  margin-top: 90px;
}
.mt93 {
  margin-top: 93px;
}
.mt100 {
  margin-top: 100px;
}

.mb0 {
  margin-bottom: 0px !important;
}
.mb6 {
  margin-bottom: 6px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb14 {
  margin-bottom: 14px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb21 {
  margin-bottom: 21px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb38 {
  margin-bottom: 38px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb44 {
  margin-bottom: 44px;
}
.mb48 {
  margin-bottom: 48px;
}
.mb50 {
  margin-bottom: 50px;
}
.mb51 {
  margin-bottom: 51px;
}
.mb55 {
  margin-bottom: 55px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb64 {
  margin-bottom: 64px;
}
.mb66 {
  margin-bottom: 66px;
}
.mb70 {
  margin-bottom: 70px;
}
.mb80 {
  margin-bottom: 80px;
}
.mb90 {
  margin-bottom: 90px;
}
.mb95 {
  margin-bottom: 95px;
}
.mb100 {
  margin-bottom: 100px;
}

.ml8 {
  margin-left: 8px;
}
.ml10 {
  margin-left: 10px;
}
.ml12 {
  margin-left: 12px;
}
.ml20 {
  margin-left: 20px;
}
.ml24 {
  margin-left: 24px;
}
.ml30 {
  margin-left: 30px;
}
.ml40 {
  margin-left: 40px;
}
.ml50 {
  margin-left: 50px;
}
.ml60 {
  margin-left: 60px;
}
.ml70 {
  margin-left: 70px;
}
.ml80 {
  margin-left: 80px;
}
.ml90 {
  margin-left: 90px;
}
.ml100 {
  margin-left: 100px;
}
.ml318 {
  margin-left: 318px;
}

.mr2 {
  margin-right: 2px;
}
.mr10 {
  margin-right: 10px;
}
.mr14 {
  margin-right: 14px;
}
.mr20 {
  margin-right: 20px;
}
.mr24 {
  margin-right: 24px;
}
.mr30 {
  margin-right: 30px;
}
.mr40 {
  margin-right: 40px;
}
.mr50 {
  margin-right: 50px;
}
.mr60 {
  margin-right: 60px;
}
.mr70 {
  margin-right: 70px;
}
.mr80 {
  margin-right: 80px;
}
.mr90 {
  margin-right: 90px;
}
.mr100 {
  margin-right: 100px;
}

// padding
.pl6 {
  padding-left: 6px;
}
.pl10 {
  padding-left: 10px;
}
