/* font-weight */
.fw100 {
  font-weight: 100;
}
.fw200 {
  font-weight: 200;
}
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
.fw800 {
  font-weight: 800;
}
.fw900 {
  font-weight: 900;
}

/* font size */
.fs12 {
  font-size: 12px;
}
.fs14 {
  font-size: 14px;
}
.fs16 {
  font-size: 16px;
}
.fs18 {
  font-size: 18px;
}
.fs20 {
  font-size: 20px;
}
.fs22 {
  font-size: 22px;
}
.fs24 {
  font-size: 24px;
}
.fs26 {
  font-size: 26px;
}
.fs28 {
  font-size: 28px;
}
.fs30 {
  font-size: 30px;
}
.fs32 {
  font-size: 32px;
}
