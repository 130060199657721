@import 'assets/styles/variable';
@import 'assets/styles/mixins';

.profile {
  margin: 0 auto;
  margin-top: 107px;
  width: 1050px;
  height: 463px;
  background: #fff;
  padding: 30px 24px 24px;
  border-radius: 10px;
  border: $secondary-border;
  position: relative;

  .profile_img_wrapper {
    position: relative;
    width: 60px;
    height: 60px;
    margin: 13px 0;

    .profile_img {
      display: block;
      @include imgCenter(100%);
      border-radius: 50%;
    }
  }

  .col2 {
    @include gap(48px);
    > div:first-child {
      position: relative;
      width: 300px;
    }
    > div:nth-child(2) {
      position: relative;
      width: 300px;
      &::before {
        @include pseudo(1px, 274px);
        position: absolute;
        right: -24px;
        background: $secondary;
      }
    }
  }

  details {
    margin-top: 8px;
    > summary {
      width: 300px;
      font-weight: 500;
      color: $primary;
      border: 1px solid $primary;
      border-radius: 2px;
      padding: 10px 12px;
      &::after {
        background: url('../../assets/img/chevron_blue.svg') center no-repeat;
      }
    }

    input,
    summary {
      height: 38px;
    }

    > div {
      padding: 29px 29px 33px;
      input + label {
        margin-top: 20px;
      }
    }

    &[open] {
      > div {
        border: $secondary-border;
        border-top: none;
      }
    }
  }

  .buttons {
    @include gap(10px);
    position: absolute;
    top: calc(100% + 24px);
    right: 0;
  }
}
