#dialog {
  width: 496px;
  height: 200px;
  background: #fff;
  padding: 26px 32px 32px 32px;
  @include flex-wrap-center;
  .dialog-title {
    display: block;
    font-size: 18px;
    text-align: center;
    margin-bottom: 26px;
  }

  menu {
    width: 100%;
    @include gap(10px);
    button {
      width: 100%;
      height: 48px;
      font-size: 18px;
    }
  }
  &.w870 {
    width: 870px;
  }
  // height
  &.h234 {
    height: 234px;
  }
  &.h346 {
    height: 346px;
  }
  &.h781 {
    height: 781px;
  }

  // 한 문장
  &.one-sentence {
    padding-top: 60px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  &.two-sentence {
    padding-top: 60px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  // 예상 진료 시간
  &.select-treat-time {
    height: 285px;
    .contents-wrapper {
      padding: 0 9px;
      flex-wrap: wrap;
      justify-content: space-between;
      display: flex;
      row-gap: 20px;
      flex-wrap: wrap;
      line-height: 38px;
      input[type='radio'] {
        display: none;
        & + label {
          border: 1px solid $primary;
          border-radius: 19px;
          height: 38px;
          width: 96px;
          text-align: center;
          font-size: 18px;
          cursor: pointer;
        }
        &:checked + label {
          background: $primary;
          color: #fff;
        }
      }
    }
  }

  // 진료 가능 시간 설정
  &.bookable {
    dl {
      @include flex-column-center;
      & + dl {
        margin-top: 30px;
      }
      &:nth-child(2) {
        > dt {
          margin-right: 27px;
        }
      }
      &:nth-child(3) {
        > dt {
          margin-right: 17px;
          &:nth-child(3) {
            margin-left: 24px;
          }
        }
      }
      &:nth-child(4) {
        > dt {
          margin-right: 12px;
        }
        input {
          text-align: right;
        }
        span {
          padding-left: 12px;
          line-height: 32px;
        }
      }
    }
    ol.week {
      @include gap(25px);
      > li {
        input[type='checkbox'] {
          display: none;
          & + label {
            cursor: pointer;
            display: block;
            @include circle(32px, #fff, 1px, $primary);
            transition: 0.1s;
          }
          &:checked + label,
          &:hover + label {
            background: $primary;
            color: #fff;
          }
        }
      }
    }
  }

  // 취소 사유 선택
  &.cancel-reason {
    width: 521px;
    height: 394px;
    .contents-wrapper {
      flex-direction: column;
      @include gap(12px);
      > div {
        @include space-between;
        gap: 20px;
        label {
          line-height: 30px;
          // &:last-child {
          word-break: keep-all;
          // }
        }
      }
    }
  }

  &.treat-check {
    > div {
      > p {
        margin-top: 24px;
        margin-bottom: 28px;
      }
      > em {
        display: inline-block;
        margin-left: 12px;
        color: #6aace9;
      }
    }
  }
}
