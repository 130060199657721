#header {
  height: $GNB-height;
  background: #fff;
  position: relative;
  z-index: 9;
  > div {
    position: relative;
    width: $content-width;
    height: 100%;

    margin: 0 auto;
    display: flex;
    gap: 20px;
    h1 {
      height: 100%;
      a {
        display: block;
        height: 100%;
        @include flex-column-center;
        img {
          display: block;
          height: 35px;
        }
      }
    }
    nav {
      width: 935px;
      @include flex-center;
      a {
        display: inline-block;
        position: relative;
        width: 110px;
        // padding: 0 20px;
        text-align: center;
        line-height: $GNB-height;
        position: relative;
        &:after {
          @include pseudo(0, 3px);
          @include absolute-row-center();
          bottom: 0;
          transition: 0.3s;
          background: $brand-blue;
        }
        &:hover,
        &.active {
          &::after {
            width: 64px;
          }
        }
        .qna_cnt {
          position: absolute;
          background-color: #f26863;
          width: 18px;
          line-height: 18px;
          text-align: center;
          color: #fff;
          border-radius: 20px;
          font-size: 10px;
          font-weight: 300;
          top: 28px;
          right: 0;
        }
      }
    }
    details {
      border: 1px solid transparent;
      position: absolute;
      top: 28px;
      right: 0;
      background: #fff;
      min-width: 170px;
      font-size: 16px;
      > summary {
        padding: 8px 10px 8px 0;
        @include flex-column-center();
        justify-content: flex-end;
        gap: 12px;
        outline: none;

        &::after {
          border-radius: 50%;
          background: url('../img/chevron_gray.svg') center/16px no-repeat;
        }
        &:hover {
          &::after {
            background-color: $secondary;
          }
        }
      }
      > ul {
        padding: 7px 0;
        > li {
          > * {
            display: block;
            width: 100%;
            line-height: 50px;
            text-align: right;
            padding-right: 31px;
            transition: 0.2s;
            &:hover {
              background: $secondary;
            }
          }
        }
      }
      &[open] {
        border-color: $secondary;
      }
    }
  }
}

#main {
  width: 100vw;
  min-width: $content-width;
  height: calc(100vh - $GNB-height);
  background: $main-bg;

  overflow-y: overlay;
  overflow-x: hidden;
  > div {
    width: $content-width;
    // height: calc($content-height - $GNB-height);
    margin: 0 auto;
    padding: 48px 0;
    > h2 {
      font-size: 24px;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
    }
  }
  .qna_cnt {
    display: inline-block;
    background-color: #f26863;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 300;
    margin-left: 16px;
  }
}

table.notice {
  border-top: 1px solid #8a8a8a;
  border-bottom: 1px solid #d0d0d0;
  width: 100%;
  display: flex;
  flex-direction: column;

  thead {
    color: #8a8a8a;
    padding: 0 15px;
  }
  tbody {
    > tr {
      cursor: pointer;
      border-top: 1px solid #d0d0d0;
      transition: 0.1s;
      padding: 0 15px;
      &:hover {
        background: #eff1f4;
      }
      td {
        &:first-child,
        &:last-child {
          color: #9b9fa7;
        }
      }
    }
  }

  tr {
    height: 46px;
    display: flex;
    > th,
    > td {
      @include flex-column-center;
      &:first-child,
      &:nth-child(3) {
        width: 80px;
      }
      &:nth-child(2) {
        flex-grow: 1;
        // width: 200px;
      }
    }
    > td:nth-child(2) {
      justify-content: flex-start;
    }
  }
}

table.qna {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  thead {
    color: #a4a6ab;
    font-size: 16px;
    @include flex-column-center();
    font-weight: 400;
    height: 44px;
    border-bottom: 1px solid #e8e8e8;
    padding-left: 24px;
  }

  tbody {
    > tr {
      font-size: 14px;
      @include flex-column-center();
      width: 100%;
      height: 58px;
      position: relative;
      padding-left: 24px;
      cursor: pointer;
      transition: 0.1s;
      &:hover {
        background: #eff1f4;
      }

      > td {
        @include textOverflow();
        &:first-child {
          > em {
            display: block;
          }
          padding-right: 50px;
        }
      }
      span:first-child {
        color: #a7a7a7;
      }
      &.empty {
        justify-content: center;
        color: #9b9fa7;
      }
    }
    tr + tr {
      border-top: 1px solid #e8e8e8;
    }
  }
}

table.calculate {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: left;
  thead {
    color: #a4a6ab;
    font-size: 14px;
    font-weight: 400;
    tr:nth-child(1) {
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      padding-left: 20px;
    }
  }

  tbody {
    height: 430px;
    background-color: #fff;
    @include scrollbarCustom($primary, #9b9fa7);
    overflow: auto;
    @include flex-column;
    border: 1px solid #d1d6e1;
    border-radius: 10px 0px 0px 10px;

    p {
      line-height: 15px;
    }
    p:nth-child(2) {
      font-size: 8px;
    }

    > tr {
      padding-left: 20px;
      font-size: 14px;
      @include flex-column-center();
      width: 100%;
      line-height: 40px;
      border-bottom: $table-border;
      position: relative;

      > td {
        @include textOverflow();
        &:first-child {
          > em {
            display: block;
          }
        }
      }
      &.empty {
        justify-content: center;
        color: #a4a6ab;
        height: 40px;
        text-align: center;
      }
    }
    tr:last-child {
      border-bottom: $table-border;
    }
  }

  td:nth-child(8) {
    font-weight: 600;
  }

  tr {
    display: flex;
    padding: 0 5px;
    gap: 2px;

    > th,
    > td {
      flex-shrink: 0;
      &:last-child {
        flex-grow: 1;
      }
    }
  }
}

table.filter {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: $secondary-border;
  border-radius: 10px;
  background: #fff;
  padding: 0 24px;
  font-size: 14px;
  margin-bottom: 15px;

  tbody {
    tr {
      @include flex-column-center();
      padding: 12px 0;
    }
    tr + tr {
      border-top: 1px solid #eee;
    }
    td:first-child {
      width: 8%;
      padding-left: 5px;
      font-weight: 600;
      font-size: 16px;
    }
    td {
      width: 80%;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 6px 32px;
    }
  }
}

// viewport width 1280px 이하 -> 좌우 padding
// @media screen and (max-width: 1280px) {
//   #header > div {
//     padding: 0 24px;
//     details {
//       right: 24px;
//     }
//   }

//   #main > div {
//     padding-left: 24px;
//     // padding-right: 24px;
//   }
// }

.searchbox {
  // @include gap(12px);
  // margin: 14px 0;
  // > * {
  &.period {
    position: relative;
    &::before {
      @include pseudo(1px, 24px);
      position: absolute;
      left: 252px;
      background: #dbdbdb;
    }
    .date {
      padding-left: 12px;
      font-weight: 500;
    }
  }
  &.period_record {
    &::before {
      left: 268px;
    }
  }
  &.name {
    width: 600px;
  }
  @include flex-column-center;
  justify-content: space-between;
  width: 600px;
  height: 72px;
  padding: 0 24px;
  background: #fff;
  border: $secondary-border;
  border-radius: 10px;
  > em {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
  }
  label {
    color: #707070;
  }
}

table.records {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  thead {
    color: #a4a6ab;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 13px;
    padding-left: 39px;
  }

  tbody {
    > tr {
      font-size: 16px;
      background: #fff;
      @include flex-column-center();
      width: 100%;
      height: 74px;
      border: $secondary-border;
      border-radius: 10px;
      position: relative;
      padding-left: 39px;
      &::before {
        @include absolute-top();
        @include pseudo(10px, 73px);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      > td {
        @include textOverflow();
        &:first-child {
          > em {
            display: block;
          }
        }
      }

      & + tr {
        margin-top: 12px;
      }

      &.before_accept {
        @include colorsByType($brand-blue);
      }
      &.before_treat {
        @include colorsByType($brand-brown);
      }
      &.cancel_accept,
      &.cancel_treat {
        @include colorsByType($brand-gray-dark);
      }
      &.after_treat {
        @include colorsByType($brand-green);
      }

      &.empty {
        justify-content: center;
        color: #9b9fa7;
      }
    }
  }

  tr {
    display: flex;
    > th,
    > td {
      &:first-child {
        width: 170px;
      }
      &:nth-child(2) {
        width: 120px;
      }
      &:nth-child(3) {
        width: 180px;
      }
      &:nth-child(4) {
        width: 160px;
      }
      &:nth-child(5) {
        width: 270px;
      }
      &:nth-child(6) {
        flex-grow: 1;
      }
    }
  }

  span {
    &.small {
      display: block;
      margin-top: 2px;
      font-weight: 400;
      font-size: 12px;
      color: #9b9fa7;
    }
    &.blue {
      color: #108cff;
    }
  }
}
