$primary: #667ff5;
$primary-dark: #4d63c9;
$secondary: #dfe3eb;
$secondary-dark: #c3c7ce;

$brand-blue: #4d6aed;
$brand-red: #ff0000;
$brand-brown: #7f4f21;
$brand-gray-dark: #8a8a8a;
$brand-green: #799900;

$GNB-height: 96px;
$content-width: 1224px;
$content-height: 1080px;
$main-bg: #fbfbfb;

// border
$secondary-border: 1px solid $secondary;
$table-border: 1px solid #d1d6e1;
