@import 'assets/styles/variable';
@import 'assets/styles/mixins';

.content_box {
  margin-top: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  font-size: 18px;
  background-color: #fff;

  .header {
    @include flex-column-center;
    border-bottom: 1px solid #e8e8e8;
    height: 116px;
    > div {
      width: 134px;
      text-align: center;
    }
    > h3 {
      font-size: 18px;
      font-weight: 500;
      flex-grow: 1;
      padding-left: 32px;
      span {
        display: block;
        font-size: 14px;
        margin-top: 16px;
        color: #9b9fa7;
        font-weight: 400;
      }
    }
  }
  .content {
    font-size: 16px;
    padding: 28px 32px;
    min-height: 500px;
    line-height: 22px;
    color: #2f2f2f;
  }
}

.img {
  display: block;
  max-width: 46%;
  margin: 0 auto 18px;
}
