@import 'assets/styles/variable';
@import 'assets/styles/mixins';

.login {
  width: 774px;
  height: 512px;
  background: #fff;
  padding: 72px 76px 0 calc(286px + 72px);
  border-radius: 10px;
  overflow: hidden;
  @include absolute-center();
  border: $secondary-border;
  &::before {
    @include pseudo(286px, 100%);
    background: url('../../assets/img/logo_vertical.svg') center/100px no-repeat;
    background-color: #f9fafc;
    border-right: $secondary-border;

    position: absolute;
    top: 0;
    left: 0;
  }

  .handymedi_desc {
    margin-top: 4px;
    margin-bottom: 64px;
  }
  .find {
    @include gap(10px);
    margin-bottom: 38px;
    & + span {
      position: absolute;
      bottom: 50px;
      font-size: 12px;
      color: #a8a8a8;
    }
  }

  .find_nav {
    margin: 12px 0 36px 0;
    > button {
      background: $secondary;
      padding: 9px 0;
      width: 50%;
      text-align: center;
      border: $secondary-border;
      color: #8a8a8a;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:nth-child(2) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &.active {
        background: #fff;
        color: #000;
        font-weight: 500;
      }
    }
  }

  .find_form {
    > .button_wrapper {
      position: absolute;
      right: 76px;
      bottom: 44px;
      @include gap(10px);
      justify-content: end;
    }
  }
}
