@import 'assets/styles/mixins';
@import 'assets/styles/variable';

.col2 {
  margin-top: 21px;
  > div {
    padding: 24px;
    border: $secondary-border;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    > div {
      display: flex;
      > label {
        font-weight: 500;
        width: 130px;
        font-size: 16px;
        color: $primary;
        line-height: 32px;

        & + textarea,
        & + input {
          width: auto;
          flex-grow: 1;
          margin: 0;
        }
      }
      // 임시 -> 체크박스 추가되면 제거
      &:last-child {
        > label {
          & + input {
            width: auto;
            flex-grow: unset;
          }
        }
      }
    }
    span {
      line-height: 32px;
    }
    ul {
      margin-bottom: 10px;
    }
  }
}
.searchbox {
  @include gap(12px);
  flex-direction: row-reverse;
  margin: 15px 0 9px;
  > * {
    &:first-child {
      position: relative;
      width: 452px;
      height: 50px;
    }
    @include flex-column-center;
    justify-content: space-between;
    width: calc(100% - 6px);
    height: 72px;
    padding: 0 28px;
    background: #fff;
    border: $secondary-border;
    border-radius: 10px;
    > em {
      font-size: 14px;
      font-weight: 500;
      margin: 0 35px 0 20px;
    }
  }
}
.content_box {
  margin-top: 20px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  font-size: 18px;

  .header {
    @include flex-column-center;
    border-bottom: 1px solid #8a8a8a;
    height: 104px;
    padding: 12px 0;
    > h3 {
      padding-left: 20px;
      font-size: 20px;
      font-weight: 500;
      flex-grow: 1;
      span:nth-child(1) {
        margin-top: 8px;
        display: block;
        font-size: 14px;
        color: #808080;
      }
      span:nth-child(2) {
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }
  .content {
    @include flex-column;
    justify-content: space-between;
    padding: 20px 20px 12px 20px;
    min-height: 255px;
    p {
      line-height: 22px;
    }
    p + ul {
      margin-top: 12px;
    }
    .no_file {
      font-size: 14px;
      color: $brand-gray-dark;
      margin-top: 6px;
    }
  }
}
.answer {
  @include gap(10px);
  p {
    font-size: 20px;
    padding: 12px 0;
  }
  span {
    color: #7e7e7e;
    font-size: 16px;
    margin-left: 10px;
  }
  img {
    width: 24px;
    height: 24px;
    margin-top: 15px;
  }
  div {
    width: 100%;
  }
}
